import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";

// declare your own store states
interface State {
  articles: Article[];
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    articles: [],
  },
});

interface Article {
  id: number;
  name: string;
  quantity: number;
}
