import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/visits",
    name: "Nuova visita",
    component: () => import("../views/NewVisit.vue"),
  },
  {
    path: "/reports",
    name: "Rapportino di intervento",
    component: () => import("../views/NewReport.vue"),
  },
  {
    path: "/complete",
    name: "Completato!",
    component: () => import("../views/Complete.vue"),
  },
  {
    path: "/more-info",
    name: "Aggiungi informazioni",
    component: () => import("../views/EnrichTicketInfo.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
